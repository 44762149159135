.page-forgot-password {
  // background-color: $auth_background;
  color: #181818;
  position: relative;
}

.forgot-password-wrapper {
  font-family: 'Matter';

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #181818 !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  .container-fluid {
    &.container-custom {
      padding: 1.2vw 5vw 0 5vw !important;
    }
  }

  .header {
    z-index: 999;
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: row;

    .logo {
      height: 2.31vw;
      width: auto;
    }

    .nav-item {
      font-size: 1.17vw;
      line-height: 1.6vw;

      span {
        color: #4d4f5b;
      }
    }
  }

  .footer {
    margin-top: -5vw;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;

    .copyright {
      color: #a6a7b8;
      font-size: 1.1vw;
      letter-spacing: 0;
      line-height: 1.25vw;
    }
  }

  h1 {
    margin-bottom: 3.1vw;
    color: #181818;
    font-size: 3.75vw;
    font-weight: 500;
    line-height: 3.95vw;
  }

  p {
    margin-bottom: 1.9vw;
    color: #4d4f5b;
    font-size: 1.17vw;
    letter-spacing: 0;
    line-height: 1.68vw;
  }

  .btn-link {
    color: #60269e;
    font-size: 1.17vw;
    line-height: 1.6vw;
    font-weight: normal;
    text-decoration: none;
    padding-bottom: 0.2vw;
  }

  label {
    color: #a6a7b8;
    font-size: 0.94vw;
    line-height: 1.9vw;
    margin-bottom: 0;
  }

  .section-content {
    padding: 8vw 0 0 0;

    .form-forgot-password,
    .success-submit {
      padding: 20vh 8vw 12vw 5vw !important;

      .custom-form-inline {
        display: flex;
        flex-direction: row;

        .textbox-form-container {
          border: none;
          border-bottom: 1px solid #f2f3f8;
          border-radius: 3vw;
          background-color: transparent;
          padding: 0.8vw 15vw 0.8vw 2.3vw;
          background-color: #eff1f7;

          .form-group {
            padding-right: 6vw;
          }

          input {
            font-size: 1.1vw;
            line-height: 1.2vw;
            border: none;
            background: transparent;
            border-radius: 0;
            margin-bottom: 0;

            ::placeholder,
            &:-ms-input-placeholder,
            &::-ms-input-placeholder {
              color: #e3e4f1;
            }
          }

          .input-validation-msg {
            right: 9.3vw;
            top: 1vw;
            bottom: 1vw;

            span {
              padding: 0.7vw;
              font-size: 1vw;
            }
          }
        }

        .wrapper-form-group {
          width: 85%;
        }

        button {
          /* Just a little styling to make it pretty */
          color: #60269e;
          font-size: 1.3vw;
          letter-spacing: 0;
          line-height: 2.2vw;
          z-index: 999;
          padding: 1vw 0;
          margin-left: -10vw;

          .feather {
            margin-top: -0.2vw;
          }
        }
      }
    }

    .btn-send-email {
      color: #ffffff;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: normal;
      height: 4vw;
      width: 9vw;
      img {
        width: 19px;
      }
    }

    .invisible {
      display: none;
    }
  }

  .section-img {
    padding-right: 0;

    img {
      width: 100%;
      min-height: 100vh;
      object-fit: cover;
      border-radius: 0 0 0 25vw;
    }
  }
}

@media (max-width: 576px) {
  .forgot-password-wrapper {
    height: auto !important;

    .header {
      .logo {
        margin-top: 10px;
        height: 7vw;
      }
    }

    .section-content {
      padding: 8vw 0 0 0;

      h1 {
        font-size: 12vw;
        line-height: 12vw;
        margin-bottom: 10vw;
      }
      a,
      p,
      input {
        font-size: 4vw !important;
        line-height: 5vw !important;
        font-weight: 100;
        margin-bottom: 10vw;
      }

      .form-forgot-password .custom-form-inline .wrapper-form-group {
        width: 100%;
      }

      .form-forgot-password .custom-form-inline .textbox-form-container {
        border-radius: 7vw;
        padding: 1.8vw 15vw 1.8vw 3.3vw;
      }

      label {
        font-size: 10px !important;
        line-height: 12px !important;
      }

      .form-forgot-password
        .custom-form-inline
        .textbox-form-container
        .input-validation-msg {
        right: 13vw;
        top: 2vw;
        bottom: 2vw;

        span {
          padding: 2vw 3vw;
          font-size: 3vw;
        }
      }

      .form-forgot-password,
      .success-submit {
        padding: 20vh 5vw 0 5vw !important;

        .custom-form-inline {
          button {
            padding: 2vw 0;
            margin-left: -20vw;

            &.btn-send-email {
              font-size: 14px !important;
              line-height: 16px !important;
              width: 13vw;
              height: 13vw;
              margin-left: -15vw;
              span {
                display: none;
              }
              img {
                width: 19px;
              }
            }
          }
        }
      }
    }

    .section-img {
      margin-top: 0;
      padding-right: 0;
      display: none;

      img {
        width: 100%;
        min-height: 50vw;
        object-fit: cover;
        border-radius: 0 0 0 25vw;
      }
    }

    .btn-link {
      font-size: inherit;
      line-height: inherit;
      font-weight: 100;
      margin-bottom: 1vw;
    }

    .footer {
      position: absolute;
      bottom: 15px;

      .copyright {
        font-size: 13px !important;
        line-height: 15px !important;
        font-weight: 100;
      }
    }
  }
}
