@import '../../scss/theme';

.page-signin {
  position: relative;
  // background: linear-gradient(45deg, #3b2789 0%, #0d023b 100%);
  color: #181818;
}

.Auth {
  font-family: 'Matter';

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #181818 !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
  }

  .container-fluid {
    &.container-custom {
      padding: 1.2vw 5vw 0 5vw !important;
    }
  }

  .header {
    z-index: 999;
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: row;

    .logo {
      height: 2.31vw;
      width: auto;
    }

    .nav-item {
      font-size: 1.17vw;
      line-height: 1.6vw;

      span {
        color: #4d4f5b;
      }
    }
  }

  .footer {
    margin-top: -5vw;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;

    .copyright {
      color: #a6a7b8;
      font-size: 1.1vw;
      letter-spacing: 0;
      line-height: 1.25vw;
    }
  }

  h1 {
    margin-bottom: 3.1vw;
    color: #181818;
    font-size: 3.75vw;
    font-weight: 500;
    line-height: 3.95vw;
  }

  p {
    margin-bottom: 35px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .btn-link {
    color: #60269e;
    font-size: 1.17vw;
    line-height: 1.6vw;
    font-weight: normal;
    text-decoration: none;
  }

  .nobreak {
    word-break: keep-all;
    white-space: nowrap;
  }

  .btn-register {
    color: #60269e;
    font-size: 1.17vw;
    line-height: 1.6vw;
    text-align: center;
    font-weight: normal;
    padding: 8px 0;
    cursor: pointer;
    text-decoration: underline;
  }

  .form-signin {
    // padding: 30px 15px;
    // width: 380px;
    // max-width: 358px;
    // height: 380px;
    // background-color   : #fff;
    // border-radius      : 10px;
    // position: absolute;
    // left: 50%;
    // top: 150px;
    // transform: translate(-50%, 0);

    label {
      color: #a6a7b8;
      font-size: 0.94vw;
      line-height: 1.9vw;
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 1.7vw;
    }

    .textbox-form-container {
      border: none;
      border-bottom: 1px solid #f2f3f8;
      border-radius: 0;
      background-color: transparent;

      input {
        font-size: 1.17vw;
        line-height: 1.2vw;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 0 0 0.5vw 0;

        ::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          color: #e3e4f1;
        }
      }
    }

    .passsword-form-group {
      position: relative;
    }

    .password-show-hide {
      border: none;
      border-bottom: 1px solid #f2f3f8;
      background-color: transparent;
      border-radius: 0;
      position: static;

      input {
        font-size: 1.17vw;
        line-height: 1.2vw;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 0 0 8px 0;

        ::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          color: #e3e4f1;
        }
      }

      .input-validation-msg {
        height: 29px;
        bottom: 5px;
        right: 0;
        top: unset;
      }

      .btn-icon {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .btn-login {
      color: #ffffff;
      font-size: 1.17vw;
      line-height: 1.6vw;
      text-align: center;
      font-weight: normal;
      height: 5.6vh;
      width: 10.6vw;
      margin-top: 3.6vh;
    }
  }

  @media (max-width: 767px) {
    .header {
      .left {
        padding-right: 15px !important;
        .d-flex {
          display: block !important;
          .logo {
            float: left;
            height: 6.5vw;
            margin-top: 1vw;
          }
          .nav-item {
            float: right;
            width: 30vw;
            font-size: 3.3vw !important;
            line-height: 4vw !important;
            margin-top: 10px;
            text-align: right;

            .btn-register {
              font-size: 3.3vw !important;
              line-height: 4vw !important;
            }
          }
        }
      }

      .nav-item,
      .btn-register {
        font-size: 10px !important;
        line-height: 12px !important;
      }
    }

    .form-signin {
      .section-img {
        display: none;
      }
      h1 {
        font-size: 10vw;
        line-height: 12vw;
        margin-top: 7vw;
        margin-bottom: 10vw;
      }
      .section-content {
        .input-validation-msg {
          height: auto;
          span {
            padding: 3vw;
            font-size: 3vw;
          }
        }
      }
      .textbox-form-container {
        input {
          font-size: 5vw;
          line-height: 11vw;
          font-weight: 100;
          height: 15vw;
          &::placeholder {
            font-size: inherit;
            line-height: inherit;
          }
        }
      }
      .password-show-hide input {
        font-size: 5vw;
        line-height: 11vw;
        font-weight: 100;
        height: 15vw;
        &::placeholder {
          font-size: inherit;
          line-height: inherit;
        }
      }
      a,
      p {
        font-size: 4vw;
        line-height: 8vw;
        font-weight: 100;
      }

      label {
        font-size: 3.5vw;
        line-height: 4vw;
        font-weight: 100;
      }

      .btn-login {
        font-size: 14px !important;
        line-height: 16px !important;
        height: auto;
        width: auto;
        font-weight: 100;
        padding: 2vw 10vw 2vw;
      }
    }

    .footer {
      position: absolute;
      bottom: 15px;
      .copyright {
        font-size: 13px !important;
        line-height: 15px !important;
        font-weight: 100;
      }
    }
  }
}
