.form-signin {
  padding-top: 8vw;

  .section-content {
    padding: 16vh 7.7vw 8vw 5.4vw !important;

    .input-validation-msg {
      height: auto;
      span {
        padding: 3vw;
        font-size: 1vw;
      }
    }

    .text-danger {
      display: none;
    }
  }

  .section-img {
    margin-top: -8vw;
    padding-right: 0;

    img {
      width: 100%;
      min-height: 100vh;
      object-fit: cover;
      border-radius: 0 0 0 25vw;
    }
  }
}

@media (max-width: 576px) {
  .form-signin {
    padding-top: 0;
    height: auto !important;

    .section-content {
      padding: 8vw 0 0 0;

      .input-validation-msg {
        height: auto;
        span {
          padding: 3vw;
          font-size: 3vw;
        }
      }

      .form-forgot-password,
      .success-submit {
        padding: 5vh 5vw 0 5vw !important;
      }
    }

    .section-img {
      margin-top: 0;
      padding-right: 0;

      img {
        width: 100%;
        min-height: 50vw;
        height: auto;
        object-fit: cover;
        border-radius: 0 0 0 25vw;
      }
    }

    .footer {
      position: relative;
    }
  }
}

.page-signin,
.page-adminsignin {
  .Toastify__toast-container--top-right {
    top: 15px;
    right: 10px;
  }
}
