$pink: #eb008b !default;
$orange: #f68e56 !default;
$red: #eb0045 !default;
$blue: #00adee !default;
$darkblue: #00adee !default;
$black: #343a3f !default;
$dark: #4d5256 !default;
$auth_background: #00adee !default;
$loader_auth: #00adee !default;
$gradient: linear-gradient(to right, #00adee, #eb008b, #fff100) !default;
$primary_workinghours: #00adee !default;
$error_notification: #e02020 !default;
$warning_notification: #febd02 !default;
$confirmation_notification: #33c15d !default;
$info_notification: #00adf2 !default;
